.sub-pack {
    .sub-card {
        .h-wrapper {
            overflow: hidden;

            h4 {
                background: #222461;
                color: #D4B284
            }

        }
    }

    .features{
        background: #363889f0;
        div{
            color: #fff;

            
        }

    }
}